@import url(https://fonts.googleapis.com/css2?family=Noto+Sans+JP:wght@400;700&display=swap);
* {
  box-sizing: border-box;
}

html {
  font-family: 'Noto Sans JP', sans-serif;
}

body {
  margin: 0;
  background-color: #3f3f3f;
}


.HeaderCartButton_button__3TyeK {
  cursor: pointer;
  font: inherit;
  border: none;
  background-color: #4d1601;
  color: white;
  padding: 0.75rem 3rem;
  display: flex;
  justify-content: space-around;
  align-items: center;
  border-radius: 25px;
  font-weight: bold;
}

.HeaderCartButton_button__3TyeK:hover,
.HeaderCartButton_button__3TyeK:active {
  background-color: #2c0d00;
}

.HeaderCartButton_icon__1xyjK {
  width: 1.35rem;
  height: 1.35rem;
  margin-right: 0.5rem;
}

.HeaderCartButton_badge__2Bbg4 {
  background-color: #b94517;
  padding: 0.25rem 1rem;
  border-radius: 25px;
  margin-left: 1rem;
  font-weight: bold;
}

.HeaderCartButton_button__3TyeK:hover .HeaderCartButton_badge__2Bbg4,
.HeaderCartButton_button__3TyeK:active .HeaderCartButton_badge__2Bbg4 {
  background-color: #92320c;
}

.HeaderCartButton_bump__1250d {
  -webkit-animation: HeaderCartButton_bump__1250d 300ms ease-out;
          animation: HeaderCartButton_bump__1250d 300ms ease-out;
}

@-webkit-keyframes HeaderCartButton_bump__1250d {
  0% {
    -webkit-transform: scale(1);
            transform: scale(1);
  }
  10% {
    -webkit-transform: scale(0.9);
            transform: scale(0.9);
  }
  30% {
    -webkit-transform: scale(1.1);
            transform: scale(1.1);
  }
  50% {
    -webkit-transform: scale(1.15);
            transform: scale(1.15);
  }
  100% {
    -webkit-transform: scale(1);
            transform: scale(1);
  }
}

@keyframes HeaderCartButton_bump__1250d {
  0% {
    -webkit-transform: scale(1);
            transform: scale(1);
  }
  10% {
    -webkit-transform: scale(0.9);
            transform: scale(0.9);
  }
  30% {
    -webkit-transform: scale(1.1);
            transform: scale(1.1);
  }
  50% {
    -webkit-transform: scale(1.15);
            transform: scale(1.15);
  }
  100% {
    -webkit-transform: scale(1);
            transform: scale(1);
  }
}

.Header_header__2p2mU {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 5rem;
  background-color: #8a2b06;
  color: white;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 10%;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.25);
  z-index: 10;
}

.Header_main-image__2ctFT {
  width: 100%;
  height: 25rem;
  z-index: 0;
  overflow: hidden;
}

.Header_main-image__2ctFT img {
  width: 110%;
  height: 100%;
  object-fit: cover;
  -webkit-transform: rotateZ(-5deg) translateY(-4rem) translateX(-1rem);
          transform: rotateZ(-5deg) translateY(-4rem) translateX(-1rem);
}

.MealsSummary_summary__cARdu {
  text-align: center;
  max-width: 45rem;
  width: 90%;
  margin: auto;
  margin-top: -10rem;
  position: relative;
  background-color: #383838;
  color: white;
  border-radius: 14px;
  padding: 1rem;
  box-shadow: 0 1px 18px 10px rgba(0, 0, 0, 0.25);
}

.MealsSummary_summary__cARdu h2 {
  font-size: 2rem;
  margin-top: 0;
}

.Card_card__32Ru1 {
  padding: 1rem;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.25);
  border-radius: 14px;
  background-color: white;
}

.Input_input__2tLsf {
  display: flex;
  align-items: center;
  margin-bottom: 0.5rem;
}

.Input_input__2tLsf label {
  font-weight: bold;
  margin-right: 1rem;
}

.Input_input__2tLsf input {
  width: 3rem;
  border-radius: 5px;
  border: 1px solid #ccc;
  font: inherit;
  padding-left: 0.5rem;
}

.MealItemForm_form__1iOZU {
  text-align: right;
}

.MealItemForm_form__1iOZU button {
  font: inherit;
  cursor: pointer;
  background-color: #8a2b06;
  border: 1px solid #8a2b06;
  color: white;
  padding: 0.25rem 2rem;
  border-radius: 20px;
  font-weight: bold;
}

.MealItemForm_form__1iOZU button:hover,
.MealItemForm_form__1iOZU button:active {
  background-color: #641e03;
  border-color: #641e03;
}
.MealItem_meal__24_oW {
  display: flex;
  justify-content: space-between;
  margin: 1rem;
  padding-bottom: 1rem;
  border-bottom: 1px solid #ccc;
}

.MealItem_meal__24_oW h3 {
  margin: 0 0 0.25rem 0;
}

.MealItem_description__2pO9L {
  font-style: italic;
}

.MealItem_price__3WeLf {
  margin-top: 0.25rem;
  font-weight: bold;
  color: #ad5502;
  font-size: 1.25rem;
}
.AvailableMeals_meals__11uis {
  max-width: 60rem;
  width: 90%;
  margin: 2rem auto;
  -webkit-animation: AvailableMeals_meals-appear__2UlKP 1s ease-out forwards;
          animation: AvailableMeals_meals-appear__2UlKP 1s ease-out forwards;
}

.AvailableMeals_meals__11uis ul {
  list-style: none;
  margin: 0;
  padding: 0;
}

@-webkit-keyframes AvailableMeals_meals-appear__2UlKP {
  from {
    opacity: 0;
    -webkit-transform: translateY(3rem);
            transform: translateY(3rem);
  }

  to {
    opacity: 1;
    -webkit-transform: translateY(0);
            transform: translateY(0);
  }
}

@keyframes AvailableMeals_meals-appear__2UlKP {
  from {
    opacity: 0;
    -webkit-transform: translateY(3rem);
            transform: translateY(3rem);
  }

  to {
    opacity: 1;
    -webkit-transform: translateY(0);
            transform: translateY(0);
  }
}

.Modal_backdrop__5hAdi {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  z-index: 20;
  background-color: rgba(0, 0, 0, 0.75);
}

.Modal_modal__2aIiP {
  position: fixed;
  top: 20vh;
  left: 5%;
  width: 90%;
  background-color: white;
  padding: 1rem;
  border-radius: 14px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.25);
  z-index: 30;
  -webkit-animation: Modal_slide-down__TDDSB 300ms ease-out forwards;
          animation: Modal_slide-down__TDDSB 300ms ease-out forwards;
}

@media (min-width: 768px) {
  .Modal_modal__2aIiP {
    width: 40rem;
    left: calc(50% - 20rem);
  }
}

@-webkit-keyframes Modal_slide-down__TDDSB {
  from {
    opacity: 0;
    -webkit-transform: translateY(-3rem);
            transform: translateY(-3rem);
  }
  to {
    opacity: 1;
    -webkit-transform: translateY(0);
            transform: translateY(0);
  }
}

@keyframes Modal_slide-down__TDDSB {
  from {
    opacity: 0;
    -webkit-transform: translateY(-3rem);
            transform: translateY(-3rem);
  }
  to {
    opacity: 1;
    -webkit-transform: translateY(0);
            transform: translateY(0);
  }
}
.CartItem_cart-item__33Nbf {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 2px solid #8a2b06;
  padding: 1rem 0;
  margin: 1rem 0;
}

.CartItem_cart-item__33Nbf h2 {
  margin: 0 0 0.5rem 0;
  color: #363636;
}

.CartItem_summary__2iSyv {
  width: 10rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.CartItem_price__1JQLI {
  font-weight: bold;
  color: #8a2b06;
}

.CartItem_amount___jB2h {
  font-weight: bold;
  border: 1px solid #ccc;
  padding: 0.25rem 0.75rem;
  border-radius: 6px;
  color: #363636;
}

.CartItem_actions__2_zCG {
  display: flex;
  flex-direction: column;
}

@media (min-width: 768px) {
  .CartItem_actions__2_zCG {
    flex-direction: row;
  }
}

.CartItem_cart-item__33Nbf button {
  font: inherit;
  font-weight: bold;
  font-size: 1.25rem;
  color: #8a2b06;
  border: 1px solid #8a2b06;
  width: 3rem;
  text-align: center;
  border-radius: 6px;
  background-color: transparent;
  cursor: pointer;
  margin-left: 1rem;
  margin: 0.25rem;
}

.CartItem_cart-item__33Nbf button:hover,
.CartItem_cart-item__33Nbf button:active {
  background-color: #8a2b06;
  color: white;
}
.Cart_cart-items__3sxjb {
  list-style: none;
  margin: 0;
  padding: 0;
  max-height: 20rem;
  overflow: auto;
}

.Cart_total__BT5-h {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-weight: bold;
  font-size: 1.5rem;
  margin: 1rem 0;
}

.Cart_actions__bVUVO {
  text-align: right;
}

.Cart_actions__bVUVO button {
  font: inherit;
  cursor: pointer;
  background-color: transparent;
  border: 1px solid #8a2b06;
  padding: 0.5rem 2rem;
  border-radius: 25px;
  margin-left: 1rem;
}

.Cart_actions__bVUVO button:hover,
.Cart_actions__bVUVO button:active {
  background-color: #5a1a01;
  border-color: #5a1a01;
  color: white;
}

.Cart_actions__bVUVO .Cart_button--alt__39TwA {
  color: #8a2b06;
}

.Cart_actions__bVUVO .Cart_button__2XY5C {
  background-color: #8a2b06;
  color: white;
}

